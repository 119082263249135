import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "primereact/resources/primereact.min.css";

import "./reset.css";
import "./index.css";

import Main from "routes/Main";
import ContactsPage from "routes/ContactsPage";
import AboutPage from "routes/AboutPage";
import TariffsPage from "routes/TariffsPage";
import ServicePage from "routes/ServicesPage";
import NotFound from "routes/NotFound";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Main />,
      errorElement: <NotFound />
    },
    {
      path: "/contacts",
      element: <ContactsPage />,
      errorElement: <NotFound />
    },
    {
      path: "/about",
      element: <AboutPage />,
      errorElement: <NotFound />
    },
    {
      path: "/services",
      element: <ServicePage />,
      errorElement: <NotFound />
    },
    {
      path: "/tariffs",
      element: <TariffsPage />,
      errorElement: <NotFound />
    },
    {
      path: "/*",
      element: <NotFound />
    }
  ],
  {
    basename: process.env.PUBLIC_URL
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

