import "./index.scoped.css"

// Card with opening description (on serivice page)

function ServiceDetail(props) {
    if (props.data[0]["exception"] != "exception") {
        return (
            <div className="detail__wrapper">
                {props.data.map(
                    (item, key) => (
                        <div className="service__detail" key={key}>

                            <summary>
                                <h3>{item.title}</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="#16122B">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </summary>

                            <div className="detail__info hide">

                                <p className="info_description">
                                    {item.desciption.map(
                                        (item, key) => (
                                            <p key={key}>{item}</p>
                                        )
                                    )}
                                </p>

                                <div className="docs__block">
                                    <p className="__primary">Документы, которые необходимо предоставить:</p>
                                    <p className="docs__list">
                                        {item.docs.map(
                                            (item, key) => (
                                                <p key={key}>{item}</p>
                                            )
                                        )}
                                    </p>
                                </div>

                                <p className="additional__info">
                                    {item.additional.map(
                                        (item, key) => (
                                            <p key={key}>{item}</p>
                                        )
                                    )}
                                </p>

                                <div className="tariffs__block">
                                    <p className="__primary">Тариф:</p>
                                    <p className="tariffs__info">{item.tariff}</p>
                                </div>

                            </div>

                        </div>
                    )
                )}
            </div>
        );
    } else {
        return (
            <div className="detail__wrapper">
                <h3>Описание:</h3>
                <p>
                    Представляет собой письменно оформленное полномочие, которым одно лицо (доверитель) наделяет другое лицо (поверенного) для представления своих интересов перед третьими лицами. Доверенность является односторонней сделкой, для ее совершения необходимо только волеизъявление доверителя. Доверитель самостоятельно определяет срок доверенности, законодательно этот срок не ограничен, однако если не указать срок доверенности, то она будет действовать 1 год с даты удостоверения нотариусом. Доверителю также необходимо решить, будет ли доверенность содержать право на передоверие. Право на передоверие означает возможность поверенного передать полномочия по доверенности в части или полностью третьему лицу.
                </p>
                <div className="block__info">
                    <div className="docs__block">
                        <p className="__primary">Документы, которые необходимо предоставить:</p>

                        <p className="docs__list">
                            <p>- паспорт</p>
                        </p>

                        <p className="additional__info">
                            Также необходимо сообщить сведения о человеке, на которого выдается доверенность (ФИО, адрес регистрации) и, соответственно, информацию о том, что именно доверяется сделать поверенному. Если доверенность касается прав на недвижимое имущество, необходимо сообщить точный адрес этого имущества.
                        </p>

                        <div className="price__list">
                            {props.data[0]["data"].map(
                            (item, key) => (
                                <div className="position" key={key}>
                                    <p className="additional__info">{item.title.slice(0, -1)}</p>

                                    {/* <div className="tariffs__block">
                                        <p className="__primary">Стоимость:</p>
                                        <p className="tariffs__info">{item.tariff}</p>
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceDetail;