import "./index.scoped.css"

// Table from excel, nothing to say)

function TariffTable(props) {
    return (
        <>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta
                name="generator"
                content="PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet"
            />
            <title>Untitled Spreadsheet</title>
            <meta name="author" content="Unknown Creator" />
            <meta name="title" content="Untitled Spreadsheet" />
            <meta name="company" content="Microsoft Corporation" />

            <table >
                <tbody><tr >
                    <td >
                        <p class="Normal" >
                            <strong>Вид нотариального действия</strong>
                        </p>
                    </td>
                    <td >
                        <p class="Normal" >
                            <strong>Федеральный тариф</strong>
                        </p>
                    </td>
                    <td >
                        <p class="Normal" >
                            <strong>Региональный тариф</strong>
                        </p>
                    </td>
                    <td >
                        <p class="Normal" >
                            <strong>Региональный тариф за нотариальные действия, совершаемые удаленно, и сделки, удостоверяемые двумя и более нотариусами</strong>
                        </p>
                    </td>
                </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Удостверение договоров, предмет который подлежит оценке </strong>
                            </p>
                            <p class="Normal" >
                                <strong>и предусмотрена обязательная</strong><strong>&nbsp; </strong><strong>нотариальная форма</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol >
                                <li class="Normal" >
                                    Договор ренты, договор пожизненного содержания с иждивением
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                9000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                12345 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="2" >
                                <li class="Normal" >
                                    Соглашение о разделе совместно нажитого имущества супругов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18526 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="3" >
                                <li class="Normal" >
                                    Договоры отчуждения недвижимости
                                </li>
                            </ol>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="4" >
                                <li class="Normal" >
                                    Договоры дарения доли в уставном капитале Обществ с ограниченной ответственностью
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="5" >
                                <li class="Normal" >
                                    Договоры ипотеки долей в праве общей собственности на недвижимое имущество (за исключением договоров ипотеки долей в общей собственности на недвижимое имущество в обеспечение возврата кредита (займа), предоставленного на приобретение или строительство или на реструктуризацию кредита (займа), предоставленного на приобретение или строительство)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,3 % от суммы договора, но не более 3000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="6" >
                                <li class="Normal" >
                                    Договоры ипотеки долей в общей собственности на недвижимое имущество в обеспечение возврата кредита (займа), предоставленного на приобретение или строительство или на реструктуризацию кредита (займа), предоставленного на приобретение или строительство
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="7" >
                                <li class="Normal" >
                                    Соглашение о предоставлении опциона на заключение договора, опционный договор ( с уплатой опционной премии)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы опционной премии
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                43000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                51224 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="8" >
                                <li class="Normal" >
                                    Договор конвертируемого займа
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                43000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                51224 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="9" >
                                <li class="Normal" >
                                    Прочие договоры, предмет которых подлежит оценке и предусмотрена обязательная нотариальная форма
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="10" >
                                <li class="Normal" >
                                    Удостоверение соглашения об управлении хозяйственным партнерством
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                43000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                51224 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="11" >
                                <li class="Normal" >
                                    Удостоверение договора инвестиционного товарищества
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                43000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                51224 руб
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Удостоверение сделок по отчуждению недвижимого имущества, для которых</strong>
                            </p>
                            <p class="Normal" >
                                <strong>не предусмотрена обязательная нотариальная форма</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="12" >
                                <li class="Normal" >
                                    До 1&nbsp;000&nbsp;000 рублей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                3000 рублей + 0,4 % от суммы сделки
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="13" >
                                <li class="Normal" >
                                    От 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                7000 руб. + 0,2 % от суммы сделки, превышающей 1000000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="14" >
                                <li class="Normal" >
                                    Свыше 10&nbsp;000&nbsp;000 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                25000 руб. + 0,1 % от суммы сделки, превышающей 10000000 рублей, а в случае отчуждения жилых помещений (квартир, комнат, жилых домов) и земельных участков, занятых жилыми домами – не более 100000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                Супругу, родителям, детям, внукам (при наличии подлинных документов, подтверждающих родство, свидетельства о рождении/ браке)
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="15" >
                                <li class="Normal" >
                                    До 10&nbsp;000&nbsp;000 рублей включительно
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                3000 рублей + 0,2% от суммы сделки
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="16" >
                                <li class="Normal" >
                                    Свыше 10&nbsp;000&nbsp;000 рублей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                23000 рублей + 0,1% суммы сделки, превышающей 10000000 рублей, но не более 50&nbsp;000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Удостоверение прочих сделок, предмет которых подлежит оценке и</strong>
                            </p>
                            <p class="Normal" >
                                <strong>не предусмотрена обязательная нотариальная форма</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="17" >
                                <li class="Normal" >
                                    До 1&nbsp;000&nbsp;000 рублей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб. + 0,3 % от суммы сделки.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="18" >
                                <li class="Normal" >
                                    От 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <ol start="19" >
                                <li class="Normal" >
                                    Свыше 10&nbsp;000&nbsp;000 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но не более 500000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="20" >
                                <li class="Normal" >
                                    Договоры ипотеки недвижимого имущества в обеспечение возврата кредита (займа), предоставленного на приобретение или строительство или на реструктуризацию кредита (займа), предоставленного на приобретение или строительство
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб. + 0,3 % от суммы сделки
                            </p>
                            <p class="Normal" >
                                5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            </p>
                            <p class="Normal" >
                                23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но не более 500000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="21" >
                                <li class="Normal" >
                                    Удостоверение медиативных соглашений, если предмет подлежит оценке, в зависимости от ее величины:
                                </li>
                            </ol>
                            <p class="Normal" >
                                До 1&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                От 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                Свыше 10&nbsp;000&nbsp;000 рублей
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб. + 0,3 % от суммы сделки.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но не более 500000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="22" >
                                <li class="Normal" >
                                    Удостоверение договоров поручительства, в зависимости от величины оценки:
                                </li>
                            </ol>
                            <p class="Normal" >
                                До 1&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                От 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                Свыше 10&nbsp;000&nbsp;000 рублей
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб. + 0,3 % от суммы сделки.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но не более 500000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="23" >
                                <li class="Normal" >
                                    Удостоверение независимой банковской гарантии, в зависимости от величины
                                </li>
                            </ol>
                            <p class="Normal" >
                                До 1&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                От 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 рублей
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                Свыше 10&nbsp;000&nbsp;000 рублей:
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб. + 0,3 % от суммы сделки.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                5000 руб. + 0,2 % от суммы договора, превышающей 1000000 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                23000 руб. + 0,1 % от суммы договора, превышающей 10000000 руб., но не более 500000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Удостоверение договоров купли-продажи или залога, предметом</strong>
                            </p>
                            <p class="Normal" >
                                <strong>которых являются доля или часть доли в уставном капитале Обществ с ограниченной ответственностью, а также удостоверение акцепта безотзывной оферты о продаже доли в уставном капитале общества.</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="24" >
                                <li class="Normal" >
                                    Удостоверение договора купли-продажи (до 1&nbsp;000&nbsp;000 рублей)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5% суммы договора, но не менее 1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="25" >
                                <li class="Normal" >
                                    Удостоверение договора купли-продажи (от 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 рублей).
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб. + 0,3% суммы договора, превышающей 1000000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="26" >
                                <li class="Normal" >
                                    Удостоверение договора купли-продажи (свыше 10&nbsp;000&nbsp;001 рублей)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                32000 руб. + 0,15% суммы договора, превышающей 10000000 руб., но не более 150000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="27" >
                                <li class="Normal" >
                                    Удостоверение договора залога (до 1&nbsp;000&nbsp;000 рублей)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5% суммы договора, но не менее 1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="28" >
                                <li class="Normal" >
                                    Удостоверение договора залога&nbsp;&nbsp; (от 1&nbsp;000&nbsp;001 до 10&nbsp;000&nbsp;000 рублей).
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб. + 0,3% суммы договора, превышающей 1000000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="29" >
                                <li class="Normal" >
                                    Удостоверение договора залога (свыше 10&nbsp;000&nbsp;001 рублей)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                32000 руб. + 0,15% суммы договора, превышающей 10000000 руб., но не более 150000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Физ. лица
                            </p>
                            <p class="Normal" >
                                20000&nbsp; руб.
                            </p>
                            <p class="Normal" >
                                Юр. лица
                            </p>
                            <p class="Normal" >
                                27000&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                35020 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="30" >
                                <li class="Normal" >
                                    Удостоверение акцепта безотзывной оферты о продаже доли в уставном капитале общества до 1&nbsp;000&nbsp;000 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % суммы договора, но не менее 1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6500 &nbsp;руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="31" >
                                <li class="Normal" >
                                    Удостоверение акцепта безотзывной оферты о продаже доли в уставном капитале общества от 1 000 001 руб. до 10 000 000 руб. включительно
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб. + 0,3 % суммы договора, превышающей
                            </p>
                            <p class="Normal" >
                                1000000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6500 &nbsp;руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="32" >
                                <li class="Normal" >
                                    Удостоверение акцепта безотзывной оферты о продаже доли в уставном капитале общества свыше 10 000 001 руб.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                32000 руб. + 0,15 % суммы договора, превышающей
                            </p>
                            <p class="Normal" >
                                10000000 руб., но не более 150000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="33" >
                                <li class="Normal" >
                                    Иной договор (соглашение), в том числе медиативное соглашение, предмет которого не подлежит оценке
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="34" >
                                <li class="Normal" >
                                    Предварительный договор
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="35" >
                                <li class="Normal" >
                                    Соглашение об определении долей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="36" >
                                <li class="Normal" >
                                    Соглашение о порядке пользования помещением
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="37" >
                                <li class="Normal" >
                                    Брачный договор
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18526 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="38" >
                                <li class="Normal" >
                                    Соглашение об уплате алиментов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                250 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8250 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                12345 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="39" >
                                <li class="Normal" >
                                    Удостоверение соглашения по оформлению в долевую собственность родителей и детей жилого помещения, приобретенного с использованием средств материнского (семейного) капитала
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8213 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="40" >
                                <li class="Normal" >
                                    Удостоверение наследственного договора.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="41" >
                                <li class="Normal" >
                                    Соглашение об изменении или о расторжении нотариально удостоверенного договора (соглашения)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                7800 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                9289 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="42" >
                                <li class="Normal" >
                                    Удостоверение заявления участника о выходе из общества с ограниченной ответственностью, не являющегося кредитной организацией
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="43" >
                                <li class="Normal" >
                                    Удостоверение требования участника общества о приобретении доли обществом
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="44" >
                                <li class="Normal" >
                                    Удостоверение оферты о продаже доли или части доли в уставном капитале общества
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="45" >
                                <li class="Normal" >
                                    Удостоверение безотзывной оферты во исполнение опциона на заключение договора
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="46" >
                                <li class="Normal" >
                                    Соглашение о предоставлении&nbsp; опциона на заключение договора (безвозмездно), удостоверение опционного договора
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                43000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                51224 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="47" >
                                <li class="Normal" >
                                    Удостоверение факта возникновения права собственности на объекты недвижимого имущества в силу приобретательной давности
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="48" >
                                <li class="Normal" >
                                    Согласие на выезд несовершеннолетних детей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                            <p class="Normal" >
                                200 руб. от двоих родителей
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="49" >
                                <li class="Normal" >
                                    Согласие супруга на совершение сделки другим супругом
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="50" >
                                <li class="Normal" >
                                    Прочие согласия (на приватизацию и др.)&nbsp;
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="51" >
                                <li class="Normal" >
                                    Отказ сособственника от преимущественного права покупки продаваемой доли в недвижимом имуществе (в т.ч. при отчуждении доли по договору мены – ст. 250 ГК РФ)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="52" >
                                <li class="Normal" >
                                    Иные юридически значимые волеизъявления
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="53" >
                                <li class="Normal" >
                                    Прочие односторонние сделки
                                </li>
                            </ol>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                            <p class="Normal" >
                                физ. лица
                            </p>
                            <p class="Normal" >
                                4500 руб.
                            </p>
                            <p class="Normal" >
                                юр. лица
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Удостоверение договоров дарения, за исключением договоров дарения недвижимого имущества и долей уставного капитала в обществе с ограниченной ответственностью.</strong>
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="54" >
                                <li class="Normal" >
                                    - детям, в том числе усыновленным, супругу, родителям, полнородным братьям и сестрам
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,3 % от суммы договора, но не менее 200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="55" >
                                <li class="Normal" >
                                    - другим лицам
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1% от суммы договора, но не менее 300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                18765 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Доверенности</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="56" >
                                <li class="Normal" >
                                    Доверенности от физических лиц (за исключением доверенностей в порядке передоверия)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб., 500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                - на получение пенсии и социальных выплат, связанных с инвалидностью -1300 руб.:
                            </p>
                            <p class="Normal" >
                                -на ведение дела в суде, подачу и (или) получение документов – 1800 руб.:
                            </p>
                            <p class="Normal" >
                                - на распоряжение банковским счетом (вкладом) – 1800 руб.:
                            </p>
                            <p class="Normal" >
                                - на право пользования и распоряжения имуществом -2000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="57" >
                                <li class="Normal" >
                                    Доверенности от имени юридических лиц
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб., 500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="58" >
                                <li class="Normal" >
                                    Доверенности в порядке передоверия
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="59" >
                                <li class="Normal" >
                                    Удостоверение распоряжения об отмене доверенности
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Завещания</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="60" >
                                <li class="Normal" >
                                    Завещание
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="61" >
                                <li class="Normal" >
                                    Принятие закрытого завещания
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="62" >
                                <li class="Normal" >
                                    Вскрытие конверта с закрытым завещанием и оглашение закрытого завещания
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="63" >
                                <li class="Normal" >
                                    Распоряжение об отмене завещания
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                700 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="64" >
                                <li class="Normal" >
                                    Завещание, условия которого предусматривают создание наследственного фонда
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                23000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="65" >
                                <li class="Normal" >
                                    Совместное завещание супругов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Копии документов</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="66" >
                                <li class="Normal" >
                                    За свидетельствование верности копий документов, выписок из документа (за страницу копии документов или выписки из них)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                10 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                140 руб.
                            </p>
                            <p class="Normal" >
                                за каждую страницу
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="67" >
                                <li class="Normal" >
                                    За удостоверение учредительных документов организации&nbsp; (копий учредительных документов в соответствии со ст. 52 ГК РФ)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                140 руб.
                            </p>
                            <p class="Normal" >
                                за каждую страницу
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Свидетельствование подлинности подписи</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="68" >
                                <li class="Normal" >
                                    - физических лиц, в том числе на заявлениях в наследственное дело, необходимых для выдачи свидетельств о праве на наследство (за исключением свидетельствования подлинности подписи последнего из заявителей на заявлении о государственной регистрации юридического лица при создании или заявлении о государственной регистрации в качестве ИП, заявлении об осуществлении государственного кадастрового учета и (или) государственной регистрации прав)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="69" >
                                <li class="Normal" >
                                    - переводчика на переводах
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="70" >
                                <li class="Normal" >
                                    - на банковских карточках физ. лиц и ИП<span > </span>
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="71" >
                                <li class="Normal" >
                                    на банковских карточках от имени юридического лица
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;(за подпись)
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="72" >
                                <li class="Normal" >
                                    на заявлениях&nbsp; в МИФНС физ. лиц и ИП (за исключением заявлений о государственной регистрации в качестве ИП)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="73" >
                                <li class="Normal" >
                                    &nbsp;на заявлениях о государственной регистрации юридического лица при создании;
                                </li>
                            </ol>
                            <p class="Normal" >
                                за подпись последнего из заявителей (единственного заявителя)
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2300 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                4300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="74" >
                                <li class="Normal" >
                                    &nbsp;на заявлениях о государственной регистрации физического лица в качестве индивидуального предпринимателя
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="75" >
                                <li class="Normal" >
                                    на заявлениях в МИФНС от имени юридического лица (за каждую подпись)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="76" >
                                <li class="Normal" >
                                    на прочих заявлениях от имени юридического лица (за исключением свидетельствования подлинности подписи на заявлении об осуществлении государственного кадастрового учета и (или) государственной регистрации прав)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="77" >
                                <li class="Normal" >
                                    - на заявлениях от имени физических и юридических лиц об осуществлении государственного кадастрового учета и (или) государственной регистрации прав
                                </li>
                            </ol>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colspan="4" >
                            <p class="Normal" >
                                <strong>Прочие нотариальные действия</strong>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="78" >
                                <li class="Normal" >
                                    Свидетельство об удостоверении решения <strong>коллегиального органа</strong> управления юридического лица, для которого <strong>предусмотрена обязательная нотариальная</strong><strong>&nbsp; </strong><strong>форма</strong>:
                                </li>
                            </ol>
                            <p class="Normal" >
                                - решение об увеличении уставного капитала, назначении исполнительного органа
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                12000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <ol start="79" >
                                <li class="Normal" >
                                    Свидетельство об удостоверении решения <strong>коллегиального органа</strong> управления юридического лица, для которого <strong>не предусмотрена обязательная нотариальная</strong><strong>&nbsp; </strong><strong>форма</strong>
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                3000 руб. за каждый час присутствия нотариуса на заседании соответствующего органа
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                12000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <ol start="80" >
                                <li class="Normal" >
                                    Выдача свидетельства об удостоверении факта принятия решения <strong>единственным участником </strong>юридического лица, <strong>для которого предусмотрена обязательная нотариальная форма (об увеличении уставного капитала, назначении исполнительного органа)</strong>
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="81" >
                                <li class="Normal" >
                                    Выдача свидетельства об удостоверении факта принятия решения <strong>единственным участником </strong>юридического лица, <strong>для которого не предусмотрена обязательная нотариальная форма</strong>
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="82" >
                                <li class="Normal" >
                                    Принятие в депозит нотариуса денежных сумм или ценных бумаг (если для такого принятия <strong>установлена обязательная нотариальная форма), </strong>за исключением принятия на депонирование нотариуса денежных средств в целях исполнения обязательств сторон по сделке, до пяти кредиторов.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5% от принятой денежной суммы или рыночной стоимости ценных бумаг, но не менее 20 рублей и не более 20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8600 руб.+800 руб. за каждого последующего кредитора, начиная с шестого.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.+1024 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="83" >
                                <li class="Normal" >
                                    Принятие в депозит нотариуса денежных средств или ценных бумаг (<strong>не установлена обязательная нотариальная форма</strong>), за исключением случаев принятия на депонирование денежных средств в целях исполнения обязательств по сделке, до пяти кредиторов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5 % от принятой денежной суммы или рыночной стоимости ценных бумаг, но не менее 1000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8600 руб.+800 руб. за каждого последующего кредитора, начиная с шестого.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.+1024 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="84" >
                                <li class="Normal" >
                                    Принятие в депозит нотариуса входящих в состав наследства наличных денежных средств.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5% от принятой денежной суммы, но не менее 1000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="85" >
                                <li class="Normal" >
                                    Принятие на депонирование нотариусом:
                                </li>
                            </ol>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                - безналичных денежных средств в целях исполнения обязательств сторон по нотариально удостоверенной сделке
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                - наличных денежных средств в целях исполнения обязательств сторон по нотариально удостоверенной сделке
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2049 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="86" >
                                <li class="Normal" >
                                    Принятие нотариусом на депонирование в иных случаях :
                                </li>
                            </ol>
                            <p class="Normal" >
                                -безналичных денежных средств, наличных денег, ценных бумаг;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -движимых вещей
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                0,5% принятой денежной суммы, рыночной стоимости ценных бумаг или заявленной депонентом стоимости имущества, но не менее 1000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                8600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="87" >
                                <li class="Normal" >
                                    Принятие в депозит нотариуса в порядке ст.327 ГК РФ денежных сумм в целях исполнения обязательств по ранее удостоверенной нотариально сделке (например, по нотариально удостоверенному договору займа, кредитор по которому уклоняется от принятия исполнения )
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                8600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                10313 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="88" >
                                <li class="Normal" >
                                    За совершение морского протеста
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                30&nbsp;000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="89" >
                                <li class="Normal" >
                                    - За совершение исполнительной надписи
                                </li>
                            </ol>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                - об обращении взыскания на заложенное имущество: 0,5% начальной продажной цены или стоимости заложенного имущества, определенной в отчете о его оценке, но не менее 1500 руб. и не более
                            </p>
                            <p class="Normal" >
                                300&nbsp;000 руб.
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                - о взыскании денежных сумм в размере до 3000 руб. включительно или об истребовании имущества стоимостью до 3000 руб. включительно: 300 руб.
                            </p>
                            <p class="Normal" >
                                - о взыскании денежных сумм в размере 3001 руб. до 10000 руб. включительно или об истребовании имущества стоимостью от 3001 руб. до 10000 руб. включительно: 500 руб.
                            </p>
                            <p class="Normal" >
                                - о взыскании денежных сумм в размере 10001 руб. до 200000 руб. включительно или об истребовании имущества стоимостью от 10001 руб. до 200000 руб. включительно: 1000 руб.
                            </p>
                            <p class="Normal" >
                                - о взыскании денежных сумм свыше 200000 руб. или об истребовании имущества стоимостью свыше 200000 руб.: 0.5% взыскиваемой суммы или стоимости истребуемого имущества, но не менее 1500 руб..
                            </p>
                        </td>
                        <td className="top">
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                7900 руб.
                            </p>
                            <p class="Normal" >
                                за исполнительную надпись об обращении взыскания на заложенное имущество
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                5200 руб.
                            </p>
                            <p class="Normal" >
                                за исключением совершения исполнительной надписи об обращении<span > </span>взыскания на заложенное имущество
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                6181 руб. за исключением совершения&nbsp; исполнительной надписи об обращении взыскания на заложенное имущество, при этом при совершении исполнительной надписи на основании кредитного договора, договора поручительства, предусматривающего солидарную ответственность поручителя по кредитному договору, или договора об оказании услуг связи в следующем размере:
                            </p>
                            <p class="Normal" >
                                до 10000 руб. включительно-279 руб.;
                            </p>
                            <p class="Normal" >
                                от 10000 руб. до 200000 руб.- 558 руб.;
                            </p>
                            <p class="Normal" >
                                от 200000 руб. до 500000 руб.- 837 руб.;
                            </p>
                            <p class="Normal" >
                                от 500000 до 1000000 руб.- 2372 руб.;
                            </p>
                            <p class="Normal" >
                                от 1000000 руб.- 3350 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="90" >
                                <li class="Normal" >
                                    За совершение протеста векселя
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1% неоплаченной суммы, но не более 20&nbsp;000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="91" >
                                <li class="Normal" >
                                    Предъявление чека к платежу и удостоверение неоплаты чека
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1% неоплаченной суммы, но не более 20&nbsp;000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                20000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="92" >
                                <li class="Normal" >
                                    За выдачу дубликатов документов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="93" >
                                <li class="Normal" >
                                    Передача заявлений или иных документов с использованием почты или иных средств связи (за исключением передачи документов лично нотариусом или работником нотариуса под расписку,&nbsp; при обращении удаленно физических и юридических лиц с&nbsp; заявлением о передаче электронных документов другим физическим и юридическим лицам)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="94" >
                                <li class="Normal" >
                                    Передача электронных документов&nbsp; при обращении удаленно физических и юридических лиц с заявлением о передаче электронных документов другим физическим и юридическим лицам
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                393 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="95" >
                                <li class="Normal" >
                                    Передача заявлений или иных документов лично нотариусом (или работником нотариуса в случаях, установленных законодательством) по месту нахождения получателя заявления или документа под расписку
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="96" >
                                <li class="Normal" >
                                    Свидетельствование верности перевода документа с одного языка на другой
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб. за каждую страницу перевода документа
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1500 руб. за каждую страницу перевода
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1844 руб. за каждую страницу
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="97" >
                                <li class="Normal" >
                                    Выдача свидетельств (об удостоверении факта нахождения в живых, нахождения гражданина в определенном месте, удостоверение факта времени предъявления документов)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="98" >
                                <li class="Normal" >
                                    Выдача свидетельства о направлении документов и свидетельства о передачи документов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2400 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="99" >
                                <li class="Normal" >
                                    Обеспечение доказательств (допрос свидетеля, осмотр письменных и вещественных доказательств, в т.ч. в сети интернет, назначение экспертизы)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                3 000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                4000 руб. за каждую страницу протокола +
                            </p>
                            <p class="Normal" >
                                150 руб. за каждую страницу приложения к протоколу
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5686 руб.+188 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="100" >
                                <li class="Normal" >
                                    Регистрация уведомления о залоге движимого имущества
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                300 руб. за каждую страницу уведомления, предоставленного в бумажном виде
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="101" >
                                <li class="Normal" >
                                    Выдача выписки из реестра уведомлений о залоге движимого имущества
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                40 руб. за каждую страницу выписки в пределах первой - десятой страниц включительно, 20 рублей за каждую страницу выписки начиная с одиннадцатой страницы
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                150 руб. за каждую страницу выписки
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="102" >
                                <li class="Normal" >
                                    Выдача выписки из реестра уведомлений о залоге движимого имущества в электронной форме
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="103" >
                                <li class="Normal" >
                                    Удостоверение равнозначности документа на бумажном носителе электронному документу
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                50 руб. за каждую страницу изготовленного нотариусом документа на бумажном носителе
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                150 руб. за каждую страницу изготовленного нотариусом документа на бумажном носителе
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="104" >
                                <li class="Normal" >
                                    Удостоверение равнозначности электронного документа документу на бумажном носителе
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                50 руб. за каждую страницу представленного нотариусу документа на бумажном носителе
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                150 руб. за каждую страницу представленного нотариусу документа на бумажном носителе
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="105" >
                                <li class="Normal" >
                                    Удостоверение равнозначности электронного документа, изготовленного нотариусом в ином формате, электронному документу, предоставленному нотариусу посредством изменения его формата (конвертации), за 1 мегабайт
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                60 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="106" >
                                <li class="Normal" >
                                    За представление документов на государственную регистрацию юридических лиц и индивидуальных предпринимателей в электронном виде
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                1000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1500 руб. + сканирование одной страницы представляемого документа 200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="107" >
                                <li class="Normal" >
                                    Внесение сведений в Единый федеральный реестр сведений о банкротстве, в Единый федеральный реестр сведений о фактах деятельности юридических лиц.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3400 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="108" >
                                <li class="Normal" >
                                    Хранение документов, за каждый день хранения каждых полных или неполных 250 листов документов
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                20 руб. за каждый день хранения
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                150 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="109" >
                                <li class="Normal" >
                                    Принятие на хранение электронного документа (пакета электронных документов) объемом до 1 мегабайта включительно
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                5 руб. за полный (неполный) год хранения, но не менее 200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                188 рублей
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="110" >
                                <li class="Normal" >
                                    Выдача принятого на хранение электронного документа (пакета электронных документов)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                188 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="111" >
                                <li class="Normal" >
                                    За выдачу свидетельства о праве собственности на долю в общем имуществе пережившему супругу на вклады, акции, иные ценные бумаги.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1800&nbsp; руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="110" >
                                <li class="Normal" >
                                    За выдачу свидетельства о праве собственности на долю в общем имуществе пережившему супругу на <span >иное</span> движимое имущество.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2800 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="113" >
                                <li class="Normal" >
                                    За выдачу свидетельства о праве собственности на долю в общем имуществе пережившего супруга на недвижимое имущество
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                200 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3800 руб. ( за объект имущества)
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="114" >
                                <li class="Normal" >
                                    За выдачу свидетельства о праве на наследство по закону и по завещанию.
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                детям, в том числе усыновленным, супругу, родителям, полнородным братьям и сестрам наследодателя - 0,3 % стоимости наследуемого имущества, но не более 100000 руб.;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                другим наследникам - 0,6 % стоимости наследуемого имущества, но не более 1000 000 руб.;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                на вклады: до 2000 руб.- 500 руб.;
                            </p>
                            <p class="Normal" >
                                от 2000 руб. до 20000 руб.- 1500 руб.;
                            </p>
                            <p class="Normal" >
                                свыше 20000 руб.- 2500 руб.;
                            </p>
                            <p class="Normal" >
                                на иное имущество, помимо недвижимого (с каждого наследника за каждый объект): 3200 руб.;
                            </p>
                            <p class="Normal" >
                                на недвижимое имущество 7000 руб. (с каждого наследника за каждый объект)
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="115" >
                                <li class="Normal" >
                                    За выдачу свидетельства о праве на наследство по завещанию, предусматривающему создание наследственного фонда
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                детям, в том числе усыновленным, супругу, родителям, полнородным братьям и сестрам наследодателя - 0,3 % стоимости наследуемого имущества, но не более 100 000 руб.;
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                            <p class="Normal" >
                                другим наследникам - 0,6 % стоимости наследуемого имущества, но не более 1 000 000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                15000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="116" >
                                <li class="Normal" >
                                    За принятие мер по охране наследства, кроме удостоверения договора доверительного управления наследственным имуществом (опись наследственного имущества)
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                600 руб./ (0 руб.)
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб. за каждый час присутствия на описи наследственного имущества
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="117" >
                                <li class="Normal" >
                                    Учреждение доверительного управления наследственным имуществом
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                40000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="118" >
                                <li class="Normal" >
                                    Удостоверение решения об учреждении личного фонда
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="119" >
                                <li class="Normal" >
                                    Удостоверение устава личного фонда
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10400 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="120" >
                                <li class="Normal" >
                                    Удостоверение условий управления личным фондом
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                10400 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="121" >
                                <li class="Normal" >
                                    Уведомление залогодателя (должника) об исполнении обязательства, обеспеченного залогом
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2500 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="122" >
                                <li class="Normal" >
                                    За выдачу свидетельства об удостоверении тождественности собственноручной подписи инвалида по зрению с факсимильным воспроизведением его собственноручной подписи
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                Освобождение от регионального тарифа на 100%&nbsp; в соответствии с п.1 Перечня льгот, утв. Приказом Минюста России от 12.09.2023 года № 253&nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="123" >
                                <li class="Normal" >
                                    Внесение сведений в&nbsp; реестр списков участников обществ с ограниченной ответственностью ЕИС нотариата
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                600 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                1700 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="124" >
                                <li class="Normal" >
                                    Выдача выписки из реестра списков участников обществ с ограниченной ответственностью ЕИС нотариата
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                40 руб. за первую-десятую страницу; далее 20 руб., начиная с одиннадцатой страницы
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                80 руб. за каждую страницу выписки
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="125" >
                                <li class="Normal" >
                                    &nbsp;Выдача выписки из реестра распоряжений об отмене доверенностей, за исключением нотариально удостоверенных доверенностей
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                80 руб. за каждую страницу выписки
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                102 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="126" >
                                <li class="Normal" >
                                    Удостоверение факта наличия сведений в Едином государственном реестре недвижимости о фамилии, об имени, отчестве и о дате рождения гражданина, являющегося правообладателем объекта недвижимости или лицо, в пользу которого зарегистрированы ограничения права или обременения объекта недвижимости
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                300 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                150 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                180 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="127" >
                                <li class="Normal" >
                                    За совершение иных нотариальных действий, предусмотренных законодательством Российской Федерации
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                100 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                2900 руб.
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                3705 руб.
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <ol start="128" >
                                <li class="Normal" >
                                    Совершение нотариального действия на выезде
                                </li>
                            </ol>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                5000 руб.-
                            </p>
                            <p class="Normal" >
                                действия, связанные с предпринимательской деятельностью и <span >осуществлением</span> корпоративных прав;
                            </p>
                            <p class="Normal" >
                                2000 руб. - доверенности на получение пенсии, представительство по вопросу оформления инвалидности, субсидий и проч. социально-значимых вопросов
                            </p>
                            <p class="Normal" >
                                4000 руб.- иные действия на выезде;
                            </p>
                            <p class="Normal" >
                                &nbsp;(За каждый час выезда)
                            </p>
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                        <td >
                            <p class="Normal" >
                                &nbsp;
                            </p>
                        </td>
                    </tr>
                </tbody></table>
        </>
    );
}

export default TariffTable;