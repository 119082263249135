import React from "react";
import { useLocation } from "react-router-dom";

import AppBody from "components/AppBody";
import AppContainer from "components/AppContainer";
import Header from "components/Sections/Header";
import Footer from "components/Sections/Footer";
import ServiceLink from "components/UI/ServiceLink/ServiceCard";
import ServiceDetail from "components/UI/ServiceDetail";

import "./index.scoped.css"

function ServicePage() {
    const header = [
        { title: "О нас", link: "/about" },
        { title: "Контакты", link: "/contacts" },
        { title: "Услуги", link: "/services?service=inheritance" },
        { title: "Тарифы", link: "/tariffs" }
    ];

    const dictionary = {
        "inheritance": "Наследство",
        "attorney": "Доверенность",
        "legal": "Юридические лица",
        "family": "Семейные отношения",
        "contract": "Договоры",
        "another": "Иные нотариальные действия"
    };

    const data = {
        "inheritance": [
            {
                title: "Завещание",
                desciption: ["Это распоряжение на случай смерти. Посредством завещания человек может завещать любое свое имущество, любым лицам и в любых долях или же лишить кого-то наследства. Имущество перейдет к наследникам только после смерти завещателя."],
                docs: ["- паспорт"],
                additional: ["Также необходимо сообщить сведения о наследниках (ФИО, дата рождения) и об имуществе, если оно будет упоминаться."],
                tariff: "100р/1900р"
            },

            {
                title: "Заявление о принятии наследства",
                desciption: ["После смерти человека определенный круг лиц имеет право получить его имущество в порядке наследования. Чтобы данное право реализовать, необходимо принять наследство. И одним из способов является подача заявления о принятии наследства нотариусу в 6-ти месячный срок с даты смерти наследодателя", "Заявление о принятии наследства должно быть направлено нотариусу того субъекта, в котором проживал умерший на момент смерти. Таким образом, если наследодатель проживал на день смерти в Санкт-Петербурге, вы можете обратиться к любому нотариусу города."],
                docs: ["- паспорт", "- свидетельство о смерти", "- документы, которые подтверждают родственную связь с умершим (свидетельство о рождении, свидетельство о заключении брака)", "- завещание (при наличии)"],
                additional: [],
                tariff: "100р/1100р"
            },

            {
                title: "Заявление об отказе от наследства",
                desciption: ["Если человек не хочет наследовать после умершего, он может обратиться к нотариусу и подать заявление об отказе от наследства, в том числе в пользу другого наследника. Отказаться можно как от всего наследства в целом, то есть по всем основаниям, так и от наследства по одному из оснований (по закону или по завещанию). Отказ от наследства является безотзывным."],
                docs: ["- паспорт"],
                additional: [],
                tariff: "100р/1100р"
            },

            {
                title: "Свидетельство о праве на наследство",
                desciption: ["По истечении 6-ти месячного срока с даты смерти наследодателя и в случае подачи заявления о принятии наследства в срок или в случае фактического принятия наследства, наследники могут обратиться к нотариусу за получением свидетельства о праве на наследство. Данное свидетельство является публичным документом, подтверждающим право на указанное в нем наследственное имущество."],
                docs: ["- паспорт"],
                additional: [],
                tariff: "100р/1100р"
            },

            {
                title: "Свидетельство о праве собственности на долю в общем имуществе супругов",
                desciption: ["После вступления в брак имущество, которое приобретается супругами, становится их общим совместным имуществом (если иное не предусмотрено брачным договором).", "После смерти лица, состоящего в браке, переживший его супруг имеет право получить свидетельство о праве собственности на долю в общем имуществе супругов, которое будет подтверждать право на принадлежащую ему ½ долю в общем совместном имуществе супругов. Переживший супруг выделяет свою долю и может распоряжаться ей по своему усмотрению, но он также может претендовать на имущество, которое остается в составе наследства умершего, так как супруг является наследником первой очереди (статья 1142 Гражданского Кодекса РФ)."],
                docs: ["- паспорт", "- свидетельство о смерти", "- свидетельство о заключении брака", "- документы, подтверждающие принадлежность имущества наследодателю на момент смерти"],
                additional: [],
                tariff: "100р/1100р"
            },

            {
                title: "Совместное завещание супругов",
                desciption: ["Лица, состоящие в зарегистрированном браке, могут обратиться к нотариусу для составления совместного завещания, посредством которого установить по обоюдному усмотрению определить последствия смерти каждого из них, в том числе наступившей одновременно: завещать общее имущество супругов, а равно имущество каждого из них любым лицам; любым образом определить доли наследников в соответствующей наследственной массе; определить имущество, входящее в наследственную массу каждого из супругов, если определение имущества, входящего в наследственную массу каждого из супругов, не нарушает прав третьих лиц; лишить наследства одного, нескольких или всех наследников по закону, не указывая причин такого лишения; включить в совместное завещание супругов иные завещательные распоряжения, возможность совершения которых предусмотрена Гражданским Кодексом РФ."],
                docs: ["- паспорта", "- свидетельство о заключении брака"],
                additional: ["Также необходимо сообщить сведения о наследниках (ФИО, дата рождения) и об имуществе, если оно будет упоминаться."],
                tariff: "200р/3600р"
            },

            {
                title: "Наследственный договор",
                desciption: ["Наследодатель вправе заключить с любым из лиц, которые могут призываться к наследованию (статья 1116), договор, условия которого определяют круг наследников и порядок перехода прав на имущество наследодателя после его смерти к пережившим наследодателя сторонам договора или к пережившим третьим лицам, которые могут призываться к наследованию (наследственный договор). Наследственный договор может также содержать условие о душеприказчике и возлагать на участвующих в наследственном договоре лиц, которые могут призываться к наследованию, обязанность совершить какие-либо не противоречащие закону действия имущественного или неимущественного характера, в том числе исполнить завещательные отказы или завещательные возложения."],
                docs: ["- паспорта", "- правоустанавливающие документы на имущество, условие о котором будет включено в наследственный договор"],
                additional: [],
                tariff: "500р/11000р"
            },

            {
                title: "Соглашение о разделе наследуемого имущества",
                desciption: ["Часто в результате наследования имущество переходит в собственность нескольких лиц. Наследственное имущество, которое находится в общей долевой собственности двух и более наследников, может быть разделено по такому соглашению между ними, но только в рамках наследственного дела у нотариуса, у которого наследственное дело заведено."],
                docs: ["- паспорт", "- документы, собранные для ведения наследственного дела"],
                additional: [],
                tariff: "500р/11000р"
            },
        ],

        "attorney": [
            {
                exception: "exception",
                data: [
                    {
                        title: "Доверенность на получение пенсии и социальных выплат, связанных с инвалидностью:",
                        tariff: "200р/1000р"
                    },

                    {
                        title: "Доверенность на ведение дела в суде, подачу и/или получение документов от имени физических лиц:",
                        tariff: "200р/1500р"
                    },
                    
                    {
                        title: "Доверенность на распоряжение банковским счетом (вкладом) от имени физических лиц:",
                        tariff: "500р/1500р"
                    },
        
                    {
                        title: "Доверенность на право пользования и/или распоряжения имуществом (за исключением доверенности на распоряжение банковским счетом (вкладом)) от имени физических лиц:",
                        tariff: "500р/1800р"
                    },
        
                    {
                        title: "Доверенности от имени юридических лиц на право пользования и/или распоряжения имуществом:",
                        tariff: "500р/2700р"
                    },
        
                    {
                        title: "Прочие доверенности от имени физических лиц:",
                        tariff: "200р/800р"
                    },
        
                    {
                        title: "Прочие доверенности от имени юридических лиц",
                        tariff: "200р/2700р"
                    },
        
                    {
                        title: "Доверенности в порядке передоверия:",
                        tariff: "200р/2700р"
                    },
        
                    {
                        title: "Удостоверение распоряжения об отмене доверенности:",
                        tariff: "500р/1200р"
                    }
                ]   
            },
        ],

        "legal": [
            {
                title: "Свидетельствование подлинности подписи на заявлениях",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Электронная передача заявлений в налоговую",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Купля-продажа/дарение доли в уставном капитале",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Удостоверение решения органов управления юридических лиц",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Предоставление документов на государственную регистрацию юридических лиц и индивидуальных предпринимателей",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Внесение сведений в реестр списков участников обществ с ограниченной ответственностью единой информационной системы нотариата",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Выдача выписки из реестра списков участников обществ с ограниченной ответственностью единой информационной системы нотариата",
                desciption: [],
                docs: [],
                additional: [],
                tariff: ""
            }
        ],


        "family": [
            {
                title: "Брачный договор",
                desciption: ["Это соглашение лиц, планирующих вступить в брак или уже находящихся в браке. Брачный договор направлен на определение имущественных прав и обязанностей каждого из супругов, как в браке, так и после его расторжения.", "По умолчанию имущество, нажитое в период брака, является их общей совместной собственностью. Договор позволяет отойти от этого правила и установить иной порядок определения принадлежности вещей:",
                    "• закрепить за одним из супругов право собственности на отдельное имущество",
                    "• предусмотреть порядок раздела общих владений в случае развода",
                    "• определить правовой статус имущества, которое будет приобретено в период брака в будущем",
                    "• обозначить способы участия в семейных доходах",
                    "• установить права и обязанности по взаимному содержанию",
                    "• закрепить порядок осуществления семейных расходов",
                    "• урегулировать иные имущественные отношения супругов",
                    "Брачный договор позволяет избежать споров о разделе имущества в бракоразводном процессе, а также является удобной альтернативой дарения общего или личного имущества одного супруга другому."],
                docs: ["- паспорта каждого из супругов", "- свидетельство о заключении брака (если брак уже заключен)", "- правоустанавливающие документы на имущество, в отношении которого подписывается договор"],
                additional: [],
                tariff: "500р/14000р"
            },

            {
                title: "Соглашение о разделе общего имущества супругов",
                desciption: ["Соглашение о разделе имущества — это договор, вследствие которой общее совместно нажитое имущество супругов становится личной собственностью каждого из них. Таким образом, соглашение о разделе имущества позволяет прекратить режим совместной собственности и устанавливает долевую (на неделимое имущество) или раздельную собственность супругов на имущество, нажитое в браке. Такое соглашение можно заключить как во время брака, так и после его расторжения."],
                docs: ["- паспорта", "- свидетельство о заключении брака, если соглашение удостоверяется во время брака или же свидетельство о расторжении брака, если соглашение удостоверяется после расторжения брака", "- правоустанавливающие документы на имущество, в отношении которого заключается соглашение"],
                additional: [],
                tariff: "0,5 % от суммы договора, но не менее 300 руб. и не более 20000 руб./11000р"
            },

            {
                title: "Соглашение об уплате алиментов",
                desciption: ["Соглашение об уплате алиментов представляет обоюдную договоренность родителей по вопросам выплаты алиментов на содержание несовершеннолетних детей. В таком соглашении определяются сумма выплат, периодичность перечислений, способ удержания платежей."],
                docs: ["- паспорт   а сторон (к иностранному паспорту прикладывается нотариальный перевод на русский язык)", "- свидетельства о рождении ребенка, расторжении брака, установлении отцовства, усыновлении, а также иные документы, подтверждающие родственные связи заявителей", "- справку о доходах плательщика", "- иные документы, свидетельствующие о праве на получение алиментов, например медицинские справки"],
                additional: [],
                tariff: "250р/5500-7550р"
            },

            {
                title: "Соглашение о порядке осуществления родительских прав родителем, проживающим отдельно от ребенка",
                desciption: ["В случае раздельного проживания родители могут заключить соглашение об определении места жительства несовершеннолетнего ребенка и о порядке осуществления родительских прав родителем, проживающим отдельно от ребенка (п. 3 ст. 65 Семейного кодекса Российской Федерации). В таком соглашении родители вправе определить место проживания ребенка, формы, частоту, продолжительность общения ребенка с родителем, проживающим отдельно от него, а также любые вопросы его участия в воспитании ребенка."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Согласие супруга на покупку/продажу недвижимости",
                desciption: ["В силу п. 3 ст. 35 СК РФ в ряде случаев для заключения сделки одном из супругов ему требуется получить нотариально удостоверенное согласие другого супруга. К сделкам, требующим обязательного нотариально удостоверенного согласия второго супруга, закон относит: - сделки по распоряжению имуществом, права на которое подлежат государственной регистрации; - сделки, для которых законом установлена обязательная нотариальная форма; - сделки, подлежащие обязательной государственной регистрации"],
                docs: [],
                additional: [],
                tariff: "500р/1800р"
            },

            {
                title: "Согласие на выезд несовершеннолетнего ребенка заграницу",
                desciption: ["Согласие на выезд – документ, который подтверждает, что оба родителя или один из них не против поездок ребенка за границу с другими людьми."],
                docs: [],
                additional: [],
                tariff: "100 (200 руб. от двух родителей)/1500р"
            }
        ],

        "contract": [
            {
                title: "Дарение",
                desciption: ["Это договор, по которому одна сторона (даритель) безвозмездно передает или обязуется передать другой стороне (одаряемому) имущество в собственность."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Купля-продажа",
                desciption: ["По договору купли-продажи одна сторона (продавец) обязуется передать вещь (товар) в собственность другой стороне (покупателю), а покупатель обязуется принять этот товар и уплатить за него определенную денежную сумму (цену)."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Мена",
                desciption: ["По договору мены каждая из сторон обязуется передать в собственность другой стороны один товар в обмен на другой."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Договор займа",
                desciption: ["По договору займа одна сторона (займодавец) передает или обязуется передать в собственность другой стороне (заемщику) деньги, вещи, определенные родовыми признаками, или ценные бумаги, а заемщик обязуется возвратить займодавцу такую же сумму денег (сумму займа) или равное количество полученных им вещей того же рода и качества либо таких же ценных бумаг."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Договор уступки прав требования",
                desciption: [""],
                docs: [],
                additional: [],
                tariff: ""
            }
        ],

        "another": [
            {
                title: "Обеспечение доказательств",
                desciption: ["Если у вас есть опасения, что в будущем вам будет затруднительно или невозможно предоставить доказательства, которые вам могут понадобиться, например, в суде, или другом административном органе, вы можете обратиться за помощью к нотариусу. По вашей просьбе он сохранит и зафиксирует данные доказательства."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Протест векселя",
                desciption: ["В Российской Федерации подтверждение определенных фактов в отношении векселя отнесено к компетенции нотариусов. Протест векселя является досудебной процедурой удостоверения факта неисполнения должником вексельного обязательства. Это составленный в публичном порядке акт, удостоверяющий бесспорные, юридически значимые для вексельного обязательства факты, с которыми законодательство связывает определенные юридические последствия."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Депозит нотариуса",
                desciption: ["Это эффективный способ исполнения финансовых обязательств должника перед кредитором, а также безопасных расчетов по сделкам. Полное название этого инструмента — публичный депозитный счет нотариуса."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Дубликаты",
                desciption: ["В случае утраты лицом, от имени или по поручению которого совершалось соответствующее нотариальное действие, документа, выражающего содержание нотариально удостоверенной сделки, исполнительной надписи, или нотариального свидетельства, экземпляр которых хранится в делах нотариальной конторы, по заявлению в письменной форме такого лица, его представителя или правопреемника выдается дубликат утраченного документа."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Копии документов",
                desciption: [""],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Регистрация залога движимого имущества",
                desciption: ["Учет залога имущества, не относящегося к недвижимым вещам, осуществляется путем регистрации уведомлений о залоге движимого имущества в реестре уведомлений о залоге движимого имущества. Регистрацией уведомления о залоге движимого признается внесение нотариусом в реестр уведомлений о залоге движимого имущества сведений, содержащихся в уведомлении о залоге движимого имущества, направленном нотариусу в случаях, установленных гражданским законодательством. В подтверждение регистрации уведомления о залоге заявителю выдается свидетельство, которое по желанию заявителя может быть выдано в форме электронного документа, подписанного квалифицированной электронной подписью нотариуса. В случае направления в электронной форме уведомления о залоге свидетельство о регистрации данного уведомления направляется нотариусом заявителю с использованием сервисов единой информационной системы нотариата."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Удостоверение равнозначности",
                desciption: ["Удостоверение равнозначности электронного документа документу на бумажном носителе означает подтверждение тождественности содержания изготовленного нотариусом электронного документа содержанию документа, представленного нотариусу на бумажном носителе. Нотариусы совершают нотариальные действия по удостоверению равнозначности:",
                "— электронного документа документу на бумажном носителе",
                "— документа на бумажном носителе электронному документу",
                "— электронного документа, изготовленного нотариусом в ином формате, электронному документу, представленному нотариусу"],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Свидетельствование верности перевода документов с одного языка на другой",
                desciption: ["Свидетельствование верности перевода необходимо при представлении документов: в государственные ведомства; в образовательные организации; в налоговые органы и органы внутренних дел; в службы и архивы ЗАГСов; в регистрирующие органы и др.",
                "Часто нотариально засвидетельствованный перевод документа требуется при предоставлении документов в посольства и консульства других стран на территории Российской Федерации.",
                "При этом при совершении нотариального действия по свидетельствованию подлинности подписи переводчика на переводе документа, нотариус не отвечает за идентичность переводимого текста оригиналу и верность перевода, а лишь подтверждает, что переведенный текст подписан в присутствии нотариуса определенным лицом — переводчиком. Нотариус предупреждает переводчика об ответственности за правильность перевода в соответствии с действующим законодательством."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Удостоверение факта",
                desciption: ["При совершении нотариального действия по удостоверению приведенных фактов, нотариусом устанавливаются и подтверждаются определенные обстоятельства. Нотариус может удостоверить факт нахождения гражданина в живых, нахождения гражданина в определенном месте, а также факт тождественности гражданина с лицом, изображенным на фотографии и факт времени предъявления документов."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Предъявление чеков к платежу и удостоверение неоплаты чеков",
                desciption: ["Нотариус по месту нахождения плательщика принимает для предъявления к платежу чек, представленный по истечении десяти дней, если чек выписан на территории Российской Федерации; представленный по истечении двадцати дней, если чек выписан на территории государств - членов Содружества Независимых Государств; представленный по истечении семидесяти дней, если чек выписан на территории какого-либо другого государства, со дня выдачи чека, но не позднее 12 часов следующего после этого срока дня.",
                "В случае неоплаты чека нотариус удостоверяет неоплату чека путем надписи на чеке и отмечает об этом в реестре. Одновременно с надписью на чеке посылается уведомление чекодателю о неоплате его чека банком и совершении надписи на чеке."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Принятие на хранение документов",
                desciption: ["Нотариус принимает на хранение документы по описи. Один экземпляр описи остается у нотариуса, другой экземпляр выдается лицу, сдавшему документы на хранение."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Морские протесты",
                desciption: ["В силу статьи 394 Кодекса торгового мореплавания Российской Федерации (далее — КТМ РФ) в случае, если во время плавания или стоянки судна имело место происшествие, которое может явиться основанием для предъявления к судовладельцу имущественных требований, капитан судна в целях обеспечения доказательств должен сделать заявление о морском протесте.",
                "Морской протест имеет целью обеспечить, насколько это возможно, полную информацию относительно обстоятельств происшествия и причин, вызвавших его, в том числе информацию об ущербе и о принятых по предотвращению или уменьшению ущерба мерах."],
                docs: [],
                additional: [],
                tariff: ""
            },

            {
                title: "Совершение нотариального действия вне помещения нотариальной конторы",
                desciption: ["При невозможности прийти в нотариальную контору самостоятельно, нотариус может приехать к заявителю на дом для совершения нотариального действия.", "Стоимость совершаемого действия в таком случае удваивается."],
                docs: [],
                additional: [],
                tariff: ""
            },
        ]
    };

    const query = useQuery();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    function getService() {
        return dictionary[query.get("service")];
    }

    return (
        <AppBody>
            <Header links={header} />
            <div className="block__wrapper">

                <div className="services__block">
                    <AppContainer>

                        <div className="services__wrapper">

                            <h2 id="title">{getService()}</h2>

                            <div id="cards" className="services__cards">
                                {data[query.get("service")].map(
                                    (detail) => (<ServiceDetail data={[detail]} />)
                                )}
                            </div>

                            <div id="links" className="services__links">
                                <ServiceLink color={getService() === "Наследство"} link={"inheritance"} img="1.svg" name={"Наследство"} />
                                <ServiceLink color={getService() === "Доверенность"} link={"attorney"} img="2.svg" name={"Доверенность"} />
                                <ServiceLink color={getService() === "Юридические лица"} link={"legal"} img="3.svg" name={"Юридические лица"} />
                                <ServiceLink color={getService() === "Семейные отношения"} link={"family"} img="4.svg" name={"Семейные отношения"} />
                                <ServiceLink color={getService() === "Договоры"} link={"contract"} img="5.svg" name={"Договоры"} />
                                <ServiceLink color={getService() === "Иные нотариальные действия"} link={"another"} img="6.svg" name={"Иные нотариальные действия"} />
                            </div>

                        </div>

                    </AppContainer>
                </div>

            </div>

            <Footer />
        </AppBody>
    )
}

export default ServicePage;